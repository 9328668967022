"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

import { EVENT_PAGE_VIEWED } from "@shared/constants";

import DataLayer from "@client/classes/data-layer/data-layer";

export default function PageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    DataLayer.events.queue(EVENT_PAGE_VIEWED);
  }, [pathname, searchParams]);

  return null;
}
