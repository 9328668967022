import(/* webpackMode: "eager" */ "/opt/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/opt/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/opt/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/opt/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/cookiebot.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/data-layer.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/event.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/facebook.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/page-view.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/tiktok.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/user-cookie.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/analytics/components/visit-cookie.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/client/src/components/page-classes/index.tsx");
import(/* webpackMode: "eager" */ "/opt/packages/ui/src/css/_index.css")