"use client";

import Script from "next/script";

import {
  COOKIEBOT,
  COOKIEBOT_ID,
  ENVIRONMENT,
  EVENT_COOKIE_CONSENT_SUBMITTED,
  EVENT_COOKIE_CONSENT_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
  PRODUCTION,
} from "@shared/constants";

import { logError } from "@shared/functions/log";

import DataLayer from "@client/classes/data-layer/data-layer";
import Event from "@client/components/analytics/components/event";

export default function Cookiebot() {
  if (!COOKIEBOT_ID) {
    logError({
      message: "Cookiebot ID is not defined.",
      event: {
        name: "Cookiebot",
        destination: COOKIEBOT,
      },
    });
  }

  return ENVIRONMENT === PRODUCTION && COOKIEBOT_ID ? (
    <>
      <Script
        id="cookiebot-cmp-script"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={COOKIEBOT_ID}
        data-consentmode="disabled"
        // NextJS docs recommend using "beforeInteractive" for cookie consent managers
        // https://nextjs.org/docs/app/api-reference/components/script#beforeinteractive
        strategy="beforeInteractive"
      />
      <Script
        id="cookiebot-event-listeners"
        onReady={() => {
          // Cookiebot banner is displayed to the user
          window.addEventListener(
            "CookiebotOnDialogDisplay",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_VIEWED);
            },
            false,
          );

          // User has consented to cookies
          window.addEventListener(
            "CookiebotOnAccept",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_SUBMITTED, {
                consented: true,
              });
            },
            false,
          );

          // User has declined cookies
          window.addEventListener(
            "CookiebotOnDecline",
            function () {
              DataLayer.events.queue(EVENT_COOKIE_CONSENT_SUBMITTED, {
                consented: false,
              });
            },
            false,
          );

          window.addEventListener(
            "CookiebotOnTagsExecuted",
            function () {
              DataLayer.events.queue(EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS);
            },
            false,
          );
        }}
      >
        <></>
      </Script>
    </>
  ) : (
    <Event event={EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS} queue={true} />
  );
}
