import {
  EVENT_LEAD_FORM_SUBMITTED,
  EVENT_STEP_VIEWED,
  EVENT_THANK_YOU_PAGE_VIEWED,
  GOOGLE_TAG_MANAGER,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";

import type DataLayer from "@client/classes/data-layer/data-layer";
import type User from "@client/classes/models/user";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";
import LeadForm from "@packages/types/lead-form";

type Window = typeof window & {
  dataLayer: [];
};

export default class GTMEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  readonly dataLayer: typeof DataLayer;

  /**
   * Initialize `GTMEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_STEP_VIEWED,
      this.handleStepView.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_LEAD_FORM_SUBMITTED,
      this.handleLeadFormSubmitted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_THANK_YOU_PAGE_VIEWED,
      this.handleThankYouPage.bind(this),
    );

    (window as Window).dataLayer = (window as Window).dataLayer || [];

    logDebug({ message: "Class 'GTMEvents' initialized" });
  }

  /**
   * Send "Step View" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleStepView(_: string, { data }: Event.Data<LeadForm.Step>) {
    if (data.step === 1) {
      this.send("questionnaire_start", {
        event: "questionnaire_start",
        debt_amount:
          this.dataLayer.user.getProp<User, "debtAmount">("debtAmount") ||
          "no_debt_tile_click",
      });
    }
  }

  /**
   * Send "lead form submitted" data
   */
  private handleLeadFormSubmitted() {
    this.send("questionnaire_end", {
      event: "questionnaire_end",
    });
  }

  /**
   * Send "Thank You Page" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleThankYouPage(
    _: string,
    { data }: Event.Data<Events.ThankYouPage>,
  ) {
    this.send("lead_routed", {
      currency: "USD",
      debt_amount: data.leadSubmission?.data.soft_pull_data?.debt_amount,
      email:
        this.dataLayer.user.getProp<User, "email">("email") ||
        data.leadSubmission?.data?.applicant?.email,
      event: "lead_routed",
      revenue: data.roasAmount,
      phone: data.leadSubmissionDraft?.data?.phoneNumber,
      provider: data.leadSubmission?.data.offers[0]?.provider,
    });
  }

  /**
   * Send event data to GTM
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data to send
   */
  private send(event: string, data?: unknown) {
    data && (window as Window).dataLayer.push(data);

    this.dataLayer.events.log(event, data || {}, GOOGLE_TAG_MANAGER);
  }
}
