import {
  ENVIRONMENT,
  EVENT_JOURNEY_STARTED,
  EVENT_STEP_SUBMITTED,
  EVENT_THANK_YOU_PAGE_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
  HEAP,
  PRODUCTION,
} from "@shared/constants";

import { logDebug, logError } from "@shared/functions/log";

import type DataLayer from "@client/classes/data-layer/data-layer";
import type Journey from "@client/classes/models/journey";
import type Visit from "@client/classes/models/user";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";
import type LeadForm from "@packages/types/lead-form";

type Heap = {
  addUserProperties: (data: Object) => void;
  track: (event: string, data: Object) => void;
};

type Window = typeof window & {
  heap: Heap;
};

export default class HeapEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  readonly dataLayer: typeof DataLayer;

  /**
   * Initialize `HeapEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_JOURNEY_STARTED,
      this.handleJourneyStarted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_STEP_SUBMITTED,
      this.handleStepSubmission.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_THANK_YOU_PAGE_VIEWED,
      this.handleThankYouPage.bind(this),
    );

    logDebug({ message: "Class 'HeapEvents' initialized" });
  }

  /**
   * Send "Journey" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleJourneyStarted(event: string) {
    this.send(event, {
      journey: this.dataLayer.journey.getProp<Journey, "name">("name"),
      journey_description: this.dataLayer.journey.getProp<
        Journey,
        "description"
      >("description"),
      journey_variation: this.dataLayer.journey.getProp<Journey, "variation">(
        "variation",
      ),
      journey_variation_published: this.dataLayer.journey.getProp<
        Journey,
        "published"
      >("published"),
      journey_variation_type: this.dataLayer.journey.getProp<
        Journey,
        "variationType"
      >("variationType"),
      journey_variation_updated: this.dataLayer.journey.getProp<
        Journey,
        "updated"
      >("updated"),
      visit_id: this.dataLayer.visit.getProp<Visit, "visitId">("visitId"),
    });
  }

  /**
   * Send "Step" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleStepSubmission(
    event: string,
    { data }: Event.Data<LeadForm.StepValues>,
  ) {
    this.send(event, {
      step: data.step,
      stepName: data.stepName,
    });
  }

  /**
   * Send "Thank You Page" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleThankYouPage(
    event: string,
    { data }: Event.Data<Events.ThankYouPage>,
  ) {
    this.send(event, {
      disposition: data.leadSubmission?.data.offers[0]?.provider,
      thank_you_page_variation: data.variation,
    });
  }

  /**
   * Send event data to Heap
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data to send
   */
  private send(event: string, data: object) {
    const heapEvent = () => {
      if (ENVIRONMENT === PRODUCTION) {
        try {
          (window as Window).heap.track(event, data);
        } catch (error) {
          logError({
            message: "Heap function heap.track() failed.",
            error,
            event: {
              name: event,
              meta: { data },
              destination: HEAP,
            },
          });
        }
      }

      this.dataLayer.events.log(event, data, HEAP);
    };

    if (this.dataLayer.events.nonEssentialScriptsReady) {
      heapEvent();
    } else {
      this.dataLayer.events.subscribe(
        EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
        () => {
          heapEvent();
        },
      );
    }
  }
}
